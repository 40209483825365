<div *ngIf="!editable" class="pos_rel">
  <span class="goBackIn ml-3" (click)="backToInvoice()">
    <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
  </span>
  <h6 class="headh6 pl-80">View {{ Itype }}</h6>
</div>
<div *ngIf="editable" class="pos_rel">
  <span class="goBackIn ml-3" (click)="backToInvoice()">
    <mat-icon class="goBack_pos" svgIcon="back_arrow"></mat-icon>
  </span>
  <h6 class="headh6 pl-80">{{ headerName }}</h6>
</div>
<div class="row invoice-body">
  <div
    class="viewPdf"
    style="right: 150px"
    *ngIf="
      (editPermissionBoolean ||
        changeApproveBoolean ||
        financeApproveBoolean) &&
      submitBtn_boolean &&
      lineTabBoolean
    "
  >
    <button class="btnUpload m-t-neg bg-blue ml-2 mr-2" (click)="editGRNData()">
      Edit GRN
    </button>
    <!-- PO Selection -->
    <p-autoComplete
      [(ngModel)]="selectedPONumber"
      (onSelect)="onSelectPO($event)"
      placeholder="Select PO Number"
      [suggestions]="filteredPO"
      (completeMethod)="filterPO($event)"
      field="PODocumentID"
      [dropdown]="true"
    >
      <ng-template let-country pTemplate="item">
        <div class="country-item">
          <div class="f-12">{{ country.PODocumentID }}</div>
        </div>
      </ng-template>
    </p-autoComplete>
    <!-- <button
      class="btnUpload bg-blue mr-2"
      (click)="open_dialog_comp('Amend')"
    >
      PO Amend request
    </button> -->
    <button
    *ngIf="[15,16,17,18,21,27,34,33].includes(subStatusId)"
      class="btnUpload m-t-neg bg-blue ml-2"
      (click)="open_dialog_comp('flip line')"
    >
      Flip PO
    </button>
  </div>
  <div class="viewPdf" style="right: 100px" *ngIf="!isPdfAvailable">
    <button class="btnUpload" (click)="DownloadPDF()">
      <i class="fa fa-download" aria-hidden="true"></i>
    </button>
  </div>
  <div class="viewPdf" *ngIf="!isPdfAvailable">
    <button class="btnUpload" (click)="viewPdf()">{{ btnText }}</button>
  </div>
  <!-- <div *ngIf="submitBtn_boolean || approveBtn_boolean" class="position_rule">
    <div class="placement-ruleChange f-12 bg-div-rule">
      Current Selected rule : {{ selectedRule }}
    </div>
    <button
      class="placement-ruleChange btnUpload bg-grn-btn"
      (click)="open_modal()"
    >
      Change
    </button>
  </div> -->
  <div *ngIf="approveBtn_boolean">
    <div
      class="placement-ruleChange f-12 bg-div-rule"
      style="left: inherit; right: 145px; position: absolute"
    >
      Current Selected Approval Type : {{ approvalType }}
    </div>
  </div>
  <!-- <div  *ngIf="submitBtn_boolean" class="changePOInput">


  </div> -->

  <div
    class="col-md-12 p-0 pl-1"
    [ngClass]="showPdf ? 'col-lg-5' : 'col-lg-12'"
  >
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li
        class="nav-item mr-2"
        pTooltip="Vendor details"
        tooltipPosition="top"
        (click)="changeTab('vendor')"
      >
        <a
          class="nav-link active"
          id="vendor-tab"
          data-toggle="tab"
          href="#vendor"
          role="tab"
          aria-controls="vendor"
          aria-selected="true"
          >Vendor</a
        >
      </li>
      <li
        class="nav-item mr-2"
        pTooltip="Header details"
        tooltipPosition="top"
        (click)="changeTab('header')"
      >
        <a
          class="nav-link"
          id="header-tab"
          data-toggle="tab"
          href="#header"
          role="tab"
          aria-controls="header"
          aria-selected="false"
          >Header</a
        >
      </li>
      <li
        class="nav-item"
        pTooltip="Line details"
        tooltipPosition="top"
        (click)="changeTab('line')"
      >
        <a
          class="nav-link"
          id="line-tab"
          data-toggle="tab"
          href="#line"
          role="tab"
          aria-controls="line"
          aria-selected="false"
          >Line details</a
        >
      </li>

      <li
        class="nav-item ml-2"
        pTooltip="PO Lines details"
        tooltipPosition="top"
        (click)="changeTab('poline')"
        *ngIf="Itype == 'Invoice'"
      >
        <a
          class="nav-link"
          id="POline-tab"
          data-toggle="tab"
          href="#POline"
          role="tab"
          aria-controls="POline"
          aria-selected="false"
          >PO Lines</a
        >
      </li>
      <li
        class="nav-item ml-2"
        pTooltip="GRN details"
        tooltipPosition="top"
        (click)="changeTab('grn')"
      >
        <a
          class="nav-link"
          id="grn-tab"
          data-toggle="tab"
          href="#grn"
          role="tab"
          aria-controls="grn"
          aria-selected="false"
          >GRN Data</a
        >
      </li>
      <li
        *ngIf=" Itype == 'Invoice'"
        class="nav-item ml-2"
        pTooltip="Support Doc"
        tooltipPosition="top"
        (click)="changeTab('support')"
      >
        <a
          [ngClass]="currentTab == 'support' ? 'bg-2' : 'bg-1'"
          class="nav-link"
          id="support-tab"
          data-toggle="tab"
          href="#support"
          role="tab"
          aria-controls="support"
          aria-selected="false"
          >Support Doc</a
        >
      </li>
      <!-- <button class="raiseAlert" pTooltip="raise an alert" tooltipPosition="top"><i class="fa fa-exclamation-triangle"
            aria-hidden="true"></i></button> -->
    </ul>
    <div class="tabsDiv bg-design-all">
      <form #form="ngForm" class="tab-content tabDiv" id="myTabContent">
        <!-- vendor -->
        <div
          *ngIf="currentTab == 'vendor'"
          class="tab-pane fade show active"
          id="vendor"
          role="tabpanel"
          aria-labelledby="vendor-tab"
        >
          <div class="displayFlex">
            <div *ngFor="let data of vendorData | keyvalue; let i = index">
              <div>
                <label class="label-head" style="margin-bottom: -5px"
                  >{{ data.key }}
                </label>
                <input
                  #inputv
                  type="text"
                  [value]="data.value"
                  [title]="data.value"
                  [disabled]="!vendorDetalilsEditBoolean"
                  class="form-control mb-1 inputHeight"
                  (blur)="method()"
                />
                <!-- (click)="drawrectangleonHighlight(i)"
                    [ngStyle]="{'border': (value.isError === 1 ) ? '2px solid red' : (value.isError === 0 && value.IsUpdated === 0  ) ? '1px solid lightgray': (value.IsUpdated === 1 ) ? '2px solid #37dcc7':'' } "
                  (change)="onChangeValue(value.TagLabel,inputv.value,value)"-->
              </div>
            </div>
          </div>
        </div>

        <!-- header -->
        <div
          *ngIf="currentTab == 'header'"
          class="tab-pane fade"
          id="header"
          role="tabpanel"
          aria-labelledby="header-tab"
        >
          <div class="displayFlex">
            <div *ngFor="let value of inputData">
              <div style="position: relative">
                <label
                  class="label-head"
                  style="margin-bottom: -5px"
                  (click)="hightlight(value)"
                  >{{ value.TagLabel }}
                  <span
                    *ngIf="value.isError >= 1"
                    [pTooltip]="value.ErrorDesc"
                    tooltipPosition="top"
                    ><i
                      class="fa fa-info-circle info_icon"
                      aria-hidden="true"
                    ></i
                  ></span>
                </label>
                <input
                  *ngIf="value.TagLabel == 'InvoiceDate'"
                  #inputv
                  type="text"
                  placeholder="dd-mm-yyyy"
                  title="dd-mm-yyyy"
                  [value]="value.Value | date : 'dd-MM-yyyy'"
                  [disabled]="!editable || fin_boolean"
                  [ngStyle]="{
                    border:
                      value.isError >= 1
                        ? '2px solid red'
                        : value.isError === 0 && value.IsUpdated === 0
                        ? '1px solid lightgray'
                        : value.IsUpdated === 1
                        ? '2px solid #37dcc7'
                        : ''
                  }"
                  (change)="onChangeValue(value.TagLabel, inputv.value, value)"
                  (blur)="saveChanges()"
                  class="form-control mb-1 inputHeight"
                />
                <input
                  *ngIf="value.TagLabel != 'InvoiceDate'"
                  #inputv
                  type="text"
                  [value]="value.Value"
                  [title]="value.Value"
                  [disabled]="!editable || fin_boolean"
                  [ngStyle]="{
                    border:
                      value.isError >= 1
                        ? '2px solid red'
                        : value.isError === 0 && value.IsUpdated === 0
                        ? '1px solid lightgray'
                        : value.IsUpdated === 1
                        ? '2px solid #37dcc7'
                        : ''
                  }"
                  (change)="onChangeValue(value.TagLabel, inputv.value, value)"
                  (blur)="saveChanges()"
                  class="form-control mb-1 inputHeight"
                />
                <span
                  *ngIf="
                    value.DocumentUpdates && value.DocumentUpdates.OldValue
                  "
                >
                  <span class="old_value"
                    >Old Value :
                    <span style="color: #f38a6b"
                      >"{{ value.DocumentUpdates.OldValue }}"</span
                    >
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- line -->
        <div
          *ngIf="currentTab == 'line'"
          class="tab-pane fade"
          id="line"
          role="tabpanel"
          aria-labelledby="line-tab"
        >
          <div class="d-flex justify-content-end">
            <div class="d-flex mr-3">
              <div class="represent_color invoice_color"></div>
              <div class="f-12">Invoice</div>
            </div>

            <div class="d-flex mr-3">
              <div class="represent_color po_color"></div>
              <div class="f-12">PO</div>
            </div>

            <!-- <div class="d-flex">
              <div class="represent_color grn_color"></div>
              <div class="f-12">GRN</div>
            </div> -->
          </div>

          <table class="w-100 mt-2">
            <!-- <tr>
            <th class="f-13 p-2" *ngFor="let tagName of tagArray">{{tagName}}</th>
            <th></th>
          </tr>

          <div *ngFor="let invoiceLine of lineItemsData">
            <tr *ngFor="let data of invoiceLine.lineitem">
              <span *ngFor="let data1 of data.DocumentLineItems | keyvalue">
                <td *ngFor="let data2 of data1 | keyvalue">{{data1.value  | json}} line</td>
              </span>
            </tr>
          </div> -->
            <tr>
              <td class="count_line">
                <span class="f-12 p-2" style="margin-top: -6px">S.No</span>
                <div
                  class="f-12 mb-3 count-h"
                  *ngFor="let tagName of lineCount; let i = index"
                >
                  {{ i + 1 }}
                </div>
              </td>
              <td
                class="f-13"
                *ngFor="let tagName of lineDisplayData"
                [ngStyle]="{
                  width:
                    tagName?.tagname == 'Description'
                      ? '-webkit-fill-available'
                      : '200px'
                }"
              >
                <span class="f-13 p-2">{{ tagName.tagname }}</span>
                <div *ngFor="let data of tagName.items">
                  <div *ngFor="let data2 of data.linedetails" class="mb-3">
                    <div
                      [ngClass]="
                        data2.invline[0]?.DocumentLineItems.isError >= 1
                          ? 'error-border'
                          : ''
                      "
                    >
                      <div
                        class="f-12 td_padding invoice_color doc_height"
                        data-text="IN"
                        [ngClass]="
                          tagName.tagname == 'Description' ? 'textType' : ''
                        "
                      >
                        <div
                          class="error-block"
                          [ngClass]="
                            data2.invline[0]?.DocumentLineItems.isError >= 1
                              ? 'error-symbol'
                              : ''
                          "
                          [pTooltip]="
                            data2.invline[0]?.DocumentLineItems.ErrorDesc
                          "
                          tooltipPosition="top"
                        ></div>
                        <input
                          type="text"
                          #lineInput
                          [title]="data2.invline[0]?.DocumentLineItems.Value"
                          [value]="data2.invline[0]?.DocumentLineItems.Value"
                          class="f-12 input_disabled w-fill"
                          style="text-transform: lowercase"
                          [disabled]="!editable || fin_boolean"
                          [readOnly]="
                            tagName.tagname == 'Discount' ||
                            tagName.tagname == 'Discount%'
                          "
                          (change)="
                            onChangeLineValue(
                              lineInput.value,
                              data2.invline[0]?.DocumentLineItems
                            )
                          "
                          (blur)="saveChanges()"
                          [ngClass]="!editable ? 'input_disabled' : ''"
                          [ngStyle]="{
                            width:
                              tagName.tagname == 'Description'
                                ? '-webkit-fill-available'
                                : '60px'
                          }"
                        />
                        <span
                          *ngIf="data2.invline[0]?.DocumentUpdates"
                          class="prev_value"
                          >prev:
                          {{ data2.invline[0]?.DocumentUpdates.OldValue }}</span
                        >
                      </div>
                      <div
                        class="f-12 td_padding po_color doc_height"
                        data-text="PO"
                        [ngClass]="
                          tagName.tagname == 'Description' ? 'textType' : ''
                        "
                      >
                        <div class="error-block"></div>
                        <input
                          type="text"
                          [title]="data2.poline[0]?.Value"
                          [value]="data2.poline[0]?.Value"
                          class="f-12 input_disabled w-fill"
                          style="text-transform: lowercase"
                          disabled
                          [ngClass]="!editable ? 'input_disabled' : ''"
                          [ngStyle]="{
                            width:
                              tagName.tagname == 'Description'
                                ? '-webkit-fill-available'
                                : '60px'
                          }"
                        />
                        <!-- <span *ngIf="(approveBtn_boolean)" class="prev_value">prev: 345</span> -->
                        <!-- <div class="f-12">
                      <select class="f-12 input_disabled form-control" placeholder="mapping"
                        [disabled]="!editable || fin_boolean" (change)="displayErrorDialog = true">
                        <option class="f-12" *ngFor="let line of totalLineItems" [value]="line">{{line}}</option>
                      </select>
                    </div> -->
                      </div>

                      <!-- <div
                        class="f-12 td_padding grn_color doc_height"
                        data-text="GR"
                        [ngClass]="
                          tagName.tagname == 'Description' ? 'textType' : ''
                        "
                      >
                        <div class="error-block"></div>
                        <input
                          type="text"
                          [title]="data2.grnline[0]?.Value"
                          [value]="data2.grnline[0]?.Value"
                          class="f-12 input_disabled w-fill"
                          style="text-transform: lowercase"
                          disabled
                          [ngClass]="!editable ? 'input_disabled' : ''"
                        />
                      </div> -->
                    </div>
                  </div>
                </div>
              </td>

              <td [ngStyle]="{ width: mappedData?.length == 0 ? '' : '35%' }">
                <!-- <div class="visiblity_hidden">Dummy Data</div> -->
                <span class="f-13 p-2 mapping_line">Line items mapping</span>
                <div style="margin-top: -5px">
                  <div *ngFor="let data of lineData[0]">
                    <div *ngFor="let data2 of data?.linedetails" class="mb-3">
                      <div class="f-12 td_padding invoice_color doc_height">
                        <input
                          type="text"
                          [title]="data2?.invline[0]?.Value"
                          [value]="data2?.invline[0]?.Value"
                          class="f-12 input_disabled visiblity_hidden"
                          style="text-transform: lowercase"
                          (blur)="displayErrorDialog = true"
                          [disabled]="!editable || fin_boolean"
                          [ngClass]="!editable ? 'input_disabled' : ''"
                        />
                      </div>
                      <div class="f-12 td_padding po_color doc_height">
                        <!-- <div class="error-block error-symbol"></div> -->

                        <div class="f-12" *ngIf="submitBtn_boolean">
                          <select
                            class="f-11 input_disabled form-control sel_drp"
                            name="lineSelection"
                            placeholder="mapping"
                            ngModel
                            [disabled]="!submitBtn_boolean"
                            (change)="
                              lineMapping(
                                data.linedetails[0].invline[0]
                                  ?.DocumentLineItems?.itemCode,
                                $event.target.value,
                                data.itemcode
                              )
                            "
                          >
                            <option class="f-11" selected disabled>
                              Select Line items
                            </option>
                            <option
                              class="f-11"
                              *ngFor="let line of lineItems"
                              [value]="line.itemCode"
                            >
                              {{ line.Value }}
                            </option>
                          </select>
                          <div
                            class="d-inline-block display-mapped"
                            *ngFor="let description of mappedData"
                          >
                            <span
                              class="f-11"
                              *ngIf="
                                description?.ItemUserMap
                                  ?.mappedinvoiceitemcode ==
                                data.linedetails[0].invline[0]
                                  ?.DocumentLineItems?.itemCode
                              "
                            >
                              {{ description?.ItemMetaData?.description }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="f-12 td_padding grn_color doc_height">
                        <input
                          type="text"
                          [title]="data2.invline[0]?.Value"
                          [value]="data2.invline[0]?.Value"
                          class="f-12 input_disabled visiblity_hidden"
                          style="text-transform: lowercase"
                          (blur)="displayErrorDialog = true"
                          [disabled]="!editable || fin_boolean"
                          [ngClass]="!editable ? 'input_disabled' : ''"
                        />
                      </div> -->
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <!--PO lines-->
        <div
          *ngIf="currentTab == 'poline'"
          class="tab-pane fade"
          id="POline"
          role="tabpanel"
          aria-labelledby="POline-tab"
        >
          <div class="refresh_btn">
            <button class="btnUpload" (click)="refreshPO()">Refresh PO</button>
          </div>
          <table class="invert" style="width: 100%" *ngIf="POlineBool">
            <tr
              class="invert_tr"
              *ngFor="let item of polineTableData; let i = index"
            >
              <td class="lineName invert_th_td">
                {{ item.TagName }}
              </td>
              <!-- <div>
                          <div class="linevalue invert_thtd">{{i+1}}</div>
                        </div> -->
              <td
                class="linevalue invert_th_td"
                *ngFor="let line1 of poLineData; let i = index"
              >
                <span>
                  <div
                    *ngFor="let line of line1 | keyvalue"
                    class="d-flex"
                    style="position: relative"
                  >
                    <span *ngIf="line.key == item.TagName">
                      <input
                        type="text"
                        [ngClass]="line.key == 'Name' ? 'w-des' : 'w-sm'"
                        [value]="line.value"
                        [title]="line.value"
                        disabled
                        class="form-control mb-1 inputHeight"
                      />
                    </span>
                  </div>
                </span>
              </td>
            </tr>
          </table>
          <div *ngIf="!POlineBool" class="f-13 text-center">
            No PO lines are available
          </div>
        </div>

        <!-- GRN Data -->
        <div
        class="tab-pane fade show"
        [ngClass]="currentTab == 'grn'?'show active':''"
        id="grn"
        role="tabpanel"
        aria-labelledby="grn-tab"
      >
        <div *ngIf="grnTabDatalength>0">
        <mat-accordion>
          <div class="row"  *ngFor="let grnitem of GRNTabData | keyvalue; let i = index;">
            <div class="col-sm-12">
              <mat-expansion-panel 
              [expanded]="true"
                (opened)="setOpened(i)"
                (closed)="setClosed(i)" style="margin-top: 10px;">
                
                <mat-expansion-panel-header class="KPIName">
                  <mat-panel-title class=" secondheadertoggle">
                    
                    <h6 class="kpi-header f-13 faPulsandMinus head-pos">
                      {{ grnitem.key }}
                    </h6>
                  
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container>
                <table class="invert" style="width: 100%">
                  <tr
                    class="invert_tr bg-wt"
                    *ngFor="let item of grnitem.value; let i = index"
                  >
                    <td
                      class="lineName invert_th_td"
                      [ngStyle]="{
                        'min-width': item.TagName == 'S.No' ? '40px' : '130px'
                      }"
                    >
                      {{ item.TagName }}
                    </td>
                    <!-- <div>
                          <div class="linevalue invert_thtd">{{i+1}}</div>
                        </div> -->
                    <td
                      class="linevalue invert_th_td"
                      *ngFor="let line1 of item.linedata; let i = index"
                    >
                      <div
                        *ngFor="let line of line1 | keyvalue"
                        class="d-flex"
                        style="position: relative"
                      >
                        <div *ngIf="line.key == 'DocumentLineItems'">
                          <div *ngIf="item.TagName == 'S.No'">
                            <div class="linevalue invert_thtd p-2">
                              {{ line.value.itemCode }}
                            </div>
                          </div>
                          <span
                            *ngIf="
                              item.TagName != 'S.No' && item.TagName != 'Actions'
                            "
                          >
                            <input
                              #inputv
                              type="text"
                              [ngClass]="
                                item.TagName == 'Description' ||
                                item.TagName == 'Name'
                                  ? 'w-des'
                                  : 'w-sm'
                              "
                              [value]="line.value.Value"
                              [title]="line.value.Value"
                              readonly
                              class="form-control mb-1 inputHeight"
                            />
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                </ng-container>
          
              </mat-expansion-panel>
            </div>
          </div>
          
          
        </mat-accordion>
        </div>
        <div *ngIf="!(grnTabDatalength>0)" class="f-13 text-center">
          No GRN data available
        </div>
      </div>

          <!-- Support Doc -->
          <div
          class="tab-pane fade"
          id="support"
          role="tabpanel"
          [ngClass]=" currentTab == 'support'? 'show active' : ''"
          aria-labelledby="support-tab"
          style="position: relative"
        >
          <div
            *ngIf="
              support_doc_list?.length > 0
            "
          >
            <table class="table">
              <tr class="f-12 lineName">
                <th>S.No</th>
                <th>Name</th>
                <th></th>
              </tr>
              <tr
                *ngFor="let file of support_doc_list; let i = index"
                class="f-11"
              >
                <td>{{ i + 1 }}</td>
                <td class="break_word">{{ file }}</td>
                <td>
                  <i
                    class="fa fa-download"
                    aria-hidden="true"
                    style="cursor: pointer"
                    (click)="downloadDoc(file)"
                  ></i>
                </td>
              </tr>
            </table>
          </div>
          <div
            *ngIf="
              !(support_doc_list?.length > 0)
            "
          >
            <div class="no_doc_div f-14">
              No Supporting Document available, if you have any please upload.
            </div>
          </div>
          <div class="file_table" *ngIf="uploadFileList.length > 0">
            <table class="table">
              <tr class="f-12">
                <th>S.No</th>
                <th>Name</th>
                <th></th>
              </tr>
              <tr
                *ngFor="let file of uploadFileList; let i = index"
                class="f-11"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ file.name }}</td>
                <td>
                  <i
                    class="fa fa-trash-o"
                    aria-hidden="true"
                    style="cursor: pointer"
                    (click)="removeUploadQueue(i)"
                  ></i>
                </td>
              </tr>
            </table>
          </div>
          <div
            *ngIf="editable "
          >
            <label
              for="img"
              class="img-browse"
              [ngClass]="uploadFileList.length > 0 ? 'disable_clas' : ''"
            >
              Attach Document
            </label>
            <input
              (change)="onSelectFile($event)"
              #fileInput
              type="file"
              id="img"
              name="img"
              accept=".pdf,.doc, .docx"
              multiple
              [disabled]="uploadFileList.length > 0"
              hidden
            />
            <!-- <input type="file" multiple (change)="uploadSupport($event)"> -->

            <button
              class="img-browse h-35 b-0 ml-2"
              (click)="uploadSupport()"
              [disabled]="!(uploadFileList.length > 0)"
              [ngClass]="!(uploadFileList.length > 0) ? 'disable_clas' : ''"
            >
              Upload
            </button>
          </div>
        </div>

        <div
          *ngIf="editable || vendorUplaodBoolean"
          class="btnFooter"
          mat-dialog-actions
        >
          <!-- <button mat-button class="btn btnVender mr-4" type="button">Change attachment</button> -->
          <button
            class="btn btnVender bg-btn-cancel mr-3"
            type="button"
            (click)="backToInvoice()"
          >
            Cancel
          </button>
          <button
            *ngIf="submitBtn_boolean"
            class="btn btnVender bg-btn-cancel mr-3"
            (click)="displayrejectDialog = true"
          >
            Reject
          </button>
          <!-- <button
            *ngIf="submitBtn_boolean && financeapproveDisplayBoolean"
            class="btn btnVender bg-btn-success mr-3"
            (click)="reviewManualApprove()"
          >
            Review to Manual Approval
          </button> -->
          <!-- <button
            *ngIf="submitBtn_boolean"
            class="btn btnVender bg-btn-success"
            (click)="send_review_modal()"
          >
            Review to Batch
          </button> -->
          <!-- <button
            *ngIf="approveBtn_boolean && financeapproveDisplayBoolean"
            class="btn btnVender bg-btn-success mr-3"
            (click)="approveChangesManual()"
          >
            Send to Manual Approval
          </button> -->
          <button
            *ngIf="submitBtn_boolean"
            class="btn btnVender bg-btn-success"
            (click)="approveChangesBatch()"
          >
            Send to Batch
          </button>

          <!-- <button *ngIf="financeApproveBoolean && fin_boolean" class="btn btnVender" style="min-width: fit-content;"
          (click)="financeApprove()">Finance
          Approve</button> -->
          <!-- <button *ngIf=" flipBoolean" class="btn btnVender mr-2 " (click)="Create()" >Create</button>
            <button *ngIf="!tabChangeLINEBoolean && flipBoolean"  class="btn btnVender mr-2" (click)="displayAddLabelDialog = true" pTooltip="Add Label" tooltipPosition="top" >Add Label</button>
            <button *ngIf="tabChangeLINEBoolean && flipBoolean" class="btn btnVender " (click)="addLine()" >Add Line</button> -->
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="showPdf" class="col-lg-7 col-12 maxPage ShowInvoiceTypeSize">
    <div>
      <section>
        <div *ngIf="isImgBoolean" class="canvasConatiner_height">
          <!-- *ngFor="let img of imgArray; let i = index " -->
          <div style="visibility: hidden">
            <div *ngIf="editable">
              <span
                [ngClass]="isRect ? 'btnInvoiceType1' : 'btnInvoiceType'"
                (click)="drawrectangle()"
                style="cursor: pointer"
                >Tag</span
              >
            </div>
            <div *ngIf="!editable">
              <span class="btnInvoiceType" style="cursor: not-allowed"
                >Tag</span
              >
            </div>
          </div>
          <div id="parentDiv">
            <canvas #canvas id="canvas1" style="height: 87vh"></canvas>
          </div>
          <span class="zoom zoom_pos_img">
            <span class="zoom-in" (click)="zoomin()"
              ><i class="fa fa-plus" aria-hidden="true"></i></span
            ><br />
            <span class="zoom-out" (click)="zoomout()"
              ><i class="fa fa-minus" aria-hidden="true"></i
            ></span>
          </span>
        </div>
        <div *ngIf="!isImgBoolean" style="position: relative">
          <div
            *ngIf="isLoaded"
            style="text-align: center; position: fixed; z-index: 100; left: 70%"
          >
            <div>
              <button
                (click)="prevPage()"
                [disabled]="page === 1"
                class="preBtn"
              >
                Prev
              </button>
              <span class="totalPages">{{ page }} / {{ totalPages }}</span>
              <button
                (click)="nextPage()"
                [disabled]="page === totalPages"
                class="preBtn"
              >
                Next
              </button>
            </div>

            <div class="rotate_text">
              <button class="preBtn" (click)="rotate(-90)">
                <i class="fa fa-undo" aria-hidden="true"></i>
              </button>
              <div class="f-12 ml-1 p-r-t">Rotate</div>
              <button class="preBtn mr-1" (click)="rotate(90)">
                <i class="fa fa-repeat" aria-hidden="true"></i>
              </button>
            </div>

            <span class="zoom zoom_pos_pdf">
              <span class="zoom-in" (click)="zoomIn()"
                ><i class="fa fa-plus" aria-hidden="true"></i></span
              ><br />
              <span class="zoom-out" (click)="zoomOut()"
                ><i class="fa fa-minus" aria-hidden="true"></i
              ></span>
            </span>
          </div>

          <pdf-viewer
            #pdfviewer
            [src]="showInvoice"
            style="width: 100%; overflow: auto"
            [show-all]="true"
            [zoom]="zoomdata"
            [original-size]="false"
            [fit-to-page]="true"
            [page]="page"
            [rotation]="rotation"
            [render-text]="true"
            [render-text-mode]="2"
            (after-load-complete)="afterLoadComplete($event)"
            (text-layer-rendered)="textLayerRendered($event)"
            (mouseup)="selectedText()"
          >
          </pdf-viewer>
        </div>
      </section>
      <!-- <img [src]="imageUrl" style="width: 100%;height: 80vh;"><br/> -->
    </div>
  </div>
</div>

<!-- Rule selection modal-->
<p-dialog
  header="Edit GRN"
  [(visible)]="displayRuleDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div style="min-height: 50vh">
    <div class="d-flex mb-3" style="gap: 20px">
      <!-- select GRN Number -->
      <p-multiSelect
        [options]="po_grn_list"
        placeholder="GRN Number"
        filter="false"
        inputStyleClass="form-control mr-2"
        optionLabel="PackingSlip"
        [maxSelectedLabels]="3"
        [selectedItemsLabel]="'{0} items selected'"
        (onChange)="addGrnLine($event)"
        name="PO_GRN_Number"
        ngModel
        required
      >
      </p-multiSelect>

      <!-- select GRN Line -->
      <p-multiSelect
        [options]="po_grn_line_list"
        placeholder="GRN Line"
        filter="false"
        inputStyleClass="form-control mr-2"
        optionLabel="GRNField"
        [maxSelectedLabels]="3"
        [selectedItemsLabel]="'{0} items selected'"
        name="PO_GRN_Number_line"
        ngModel
        required
        [(ngModel)]="PO_GRN_Number_line"
      >
      </p-multiSelect>

      <!-- <button class="btnUpload" >Add</button>  -->
    </div>
    <div>
      <p-table
        *ngIf="PO_GRN_Number_line?.length > 0"
        [value]="PO_GRN_Number_line"
        [paginator]="false"
        [columns]="summaryColumn"
        styleClass="p-datatable-striped"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of columns"
              [pTooltip]="col.header"
              tooltipPosition="top"
              class="lineName"
              [ngStyle]="{ width: col.header == 'GRN Number' ? '100px' : '' }"
            >
              {{ col.header }}
            </th>
            <th class="action_Zindex" style="width: 60px">Actions</th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-invoiceData
          let-columns="columns"
          let-rowIndex="rowIndex"
        >
          <tr>
            <td *ngFor="let col of columns" [title]="invoiceData[col.field]">
              {{ invoiceData[col.field] }}
            </td>
            <td>
              <i
                class="fa fa-trash-o"
                style="cursor: pointer"
                (click)="deleteGRNEdit(rowIndex, invoiceData)"
              ></i>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage"> </ng-template>
        <ng-template pTemplate="paginatorleft"> </ng-template>
        <ng-template pTemplate="paginatorright"> </ng-template>
      </p-table>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="displayRuleDialog = false"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btnVender bg-btn-success"
        [disabled]="!(PO_GRN_Number_line?.length > 0)"
        (click)="ChangeGRNData()"
      >
        Update
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- edit type selection modal-->
<p-dialog
  header="Select Error type "
  [(visible)]="displayErrorDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '31vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <mat-radio-group
      class="example-radio-group"
      [(ngModel)]="SelectErrorOption"
    >
      <mat-radio-button
        class="example-radio-button f-13"
        *ngFor="let rule of givenErrors"
        [value]="rule.idbatcherrortypes"
      >
        {{ rule.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="cancelSelectErrorRule()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btnVender bg-btn-success"
        (click)="updateLine()"
      >
        Save Changes
      </button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [header]="headerpop"
  [(visible)]="progressDailogBool"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: p_width }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [closeIcon]="false"
>
  <div *ngIf="headerpop == 'Batch Progress'">
    <div class="f-12" *ngIf="!(batchData?.length > 0)">
      Hey, Please hold on the batch is running...
    </div>
    <ul class="f-12" *ngIf="batchData?.length > 0">
      <li *ngFor="let st of batchData">
        <span [ngStyle]="{ color: st.status == 1 ? 'green' : 'red' }">{{
          st.msg
        }}</span>
      </li>
    </ul>

    <div *ngIf="batch_count > 2" class="f-12 mt-2" style="color:red;">
      Oops, looks like something is wrong with the invoice, Please check with the Serina support team.
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button
        *ngIf="headerpop == 'Batch Progress'"
        type="button"
        class="btn btnVender bg-btn-success mt-2"
        (click)="isBatchFailed && batch_count <= 2 ? syncBatch() :routeToMapping()"
      >
        <span>{{ isBatchFailed && batch_count <= 2 ? "Re-try":"Next" }}</span>
      </button>
    </div>
  </ng-template>
</p-dialog>

<!-- Reject modal-->
<p-dialog
  header="ADD Rejection Comments "
  [(visible)]="displayrejectDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '31vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
>
  <div>
    <!-- <label class="label-head">
      <input
        type="radio"
        name="addLabel"
        [(ngModel)]="rejectOption.value"
        value="IT_reject"
        required
      />
      <span class="ml-2 f-13">IT Reject</span>
    </label>
    <br />

    <label class="label-head" *ngIf="!isServiceData">
      <input
        type="radio"
        name="addLabel"
        [(ngModel)]="rejectOption.value"
        value="vendor_reject"
        required
      />
      <span class="ml-2 f-13"> Vendor Reject</span>
    </label>
    <br /> -->

    <label class="label-head mt-2 mb-0">Comments</label>
    <textarea
      name="comments"
      class="form-control"
      style="font-size: 13px"
      minlength="10"
      [(ngModel)]="rejectionComments"
      cols="50"
      rows="2"
      ngModel
      #comments="ngModel"
      required
    ></textarea>
    <small class="noteCss">NOTE: Add atleast 10 characters</small>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btnVender bg-btn-cancel"
        (click)="displayrejectDialog = false"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btnVender bg-btn-success"
        [disabled]="comments.invalid"
        [ngStyle]="{ cursor: comments.invalid ? 'not-allowed' : 'pointer' }"
        (click)="Reject()"
      >
        Send for Rejection
      </button>
    </div>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
<ngx-spinner
  bdColor="rgba(251, 251, 251, 0.8)"
  size="medium"
  color="#070900"
  [fullScreen]="false"
  type="ball-spin-clockwise"
>
  <p style="color: rgb(0, 0, 0)"></p>
</ngx-spinner>
