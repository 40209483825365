import { AlertService } from './../../../services/alert/alert.service';
import { ExceptionsService } from './../../../services/exceptions/exceptions.service';

import { AuthenticationService } from './../../../services/auth/auth-service.service';
import { DataService } from './../../../services/dataStore/data.service';
import { Subscription, throwError } from 'rxjs';
import { PermissionService } from './../../../services/permission.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/services/shared.service';
import { TaggingService } from './../../../services/tagging.service';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
// import { fabric } from 'fabric';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormGroup, NgForm } from '@angular/forms';
// import * as $ from 'jquery';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { comaprisionLineData } from './testingLineData';
import { FormCanDeactivate } from '../../can-deactivate/form-can-deactivate';
import { SettingsService } from 'src/app/services/settings/settings.service';
import IdleTimer from '../../idleTimer/idleTimer';
import * as fileSaver from 'file-saver';
import { PopupComponent } from '../../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map, take } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-comparision3-way',
  templateUrl: './comparision3-way.component.html',
  styleUrls: [
    './comparision3-way.component.scss',
    '../../invoice/view-invoice/view-invoice.component.scss',
  ],
})
export class Comparision3WayComponent
  extends FormCanDeactivate
  implements OnInit
{
  @ViewChild('canvas') canvas;
  zoomX = 1;
  @ViewChild(PdfViewerComponent, { static: false })
  private pdfViewer: PdfViewerComponent;

  @ViewChild('pdfviewer') pdfviewer;
  @ViewChild('form')
  form: NgForm;
  isEditable: boolean;
  editable: boolean;
  rect: any;
  rectCoords: any;
  inputData = [];
  vendorDetails: FormGroup;
  isRect: boolean;
  isTagged: boolean = false;

  mergedArray: any;
  inputDisplayArray = [];
  vendorData = [];
  lineDisplayData: any;
  lineData = [];
  Itype: string;
  updateInvoiceData: any = [];
  imgArray: { id: string; url: string }[];
  headerName: string;
  editPermissionBoolean: boolean;
  changeApproveBoolean: boolean;
  financeApproveBoolean: boolean;
  fin_boolean: boolean;
  submitBtn_boolean: boolean;
  approveBtn_boolean: boolean;
  innerHeight: number;
  InvoiceHeight: number = 560;
  zoomdata: number = 1;
  showInvoice: any;
  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;
  invoiceID: any;
  routeIdCapture: Subscription;
  byteArray: Uint8Array;

  vendorDetalilsEditBoolean: boolean = false;
  displayRuleDialog: boolean;
  displayErrorDialog: boolean;
  SelectRuleOption = { value: '' };
  SelectErrorOption;
  givenErrors = ['Alternative', 'Identical', 'Adjustment', 'OCR Error'];
  givenRules: any;
  rejectionComments: string = '';

  vendorUplaodBoolean: boolean;

  isPdfAvailable: boolean;
  userDetails: any;
  showPdf: boolean;
  btnText = 'View PDF';
  lineCompareData;
  lineHeaders = ['Line No', 'ItemCode', 'Quantity', 'Unit price'];
  mockDataInvoice = [1, 'PRoduct34', 445, 56];
  totalLineItems = ['abc', 'xyz'];
  selectedPONumber;
  poList = [];
  filteredPO: any[];
  selectedRule = '3 way';
  lineItemsData: any;
  tagArray: any[];

  lineCount = [];
  save_rule_boolean: boolean;
  selectedRuleID: any;
  approvalType: any;
  currentTab = 'vendor';
  lineItems: any;
  inv_itemcode: any;
  po_itemcode: any;
  vendorAcId: any;
  mappedData: any;
  zoomVal:number = 0.8;

  isImgBoolean:boolean;
  financeapproveDisplayBoolean: boolean;
  displayrejectDialog: boolean;
  content_type: string;
  // lineDescription ="Select Line items";
  timer: any;
  callSession: any;
  invoiceNumber = '';
  vendorName: any;
  lineTabBoolean: boolean;
  rotation = 0;
  poLineData = [];
  isAdmin:boolean;
  po_grn_list = [];
  po_grn_line_list = [];
  filteredPO_GRN = [];
  GRNData: any;
  PO_GRN_Number_line = [];
  grnEditData = [];
  summaryColumn = [
    { field: 'PackingSlip', header: 'GRN Number' },
    { field: 'GRNField', header: 'GRN Field' },

  ];
  ColumnLengthVendor: number;
 
  batchData: any;
  progressDailogBool: boolean;
  portalName: string = 'customer';
  GRNDialogBool: boolean;
  headerpop: string = '350px';
  descrptonBool = false;
  p_width: string;
   polineTableData = [
    { TagName: 'LineNumber', linedata: [] },
    { TagName: 'ItemId', linedata: [] },
    { TagName: 'Name', linedata: [] },
    { TagName: 'ProcurementCategory', linedata: [] },
    { TagName: 'PurchQty', linedata: [] },
    { TagName: 'UnitPrice', linedata: [] },
    { TagName: 'DiscAmount', linedata: [] },
    { TagName: 'DiscPercent', linedata: [] }
  ];
  POlineBool: boolean;
  poDocId: any;
  po_num: any;
  subStatusId: any;
  isBatchFailed: boolean;
  batch_count = 0;
  support_doc_list = [];
  progress: any;
  uploadFileList = [];
  refreshPOBool: boolean;
  GRNTabData: any;
  grnTabDatalength: number;
  constructor(
    fb: FormBuilder,
    private tagService: TaggingService,
    private router: Router,
    private authService: AuthenticationService,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private exceptionService: ExceptionsService,
    private AlertService: AlertService,
    private messageService: MessageService,
    private SpinnerService: NgxSpinnerService,
    private permissionService: PermissionService,
    private dataService: DataService,
    private settingService: SettingsService,
    private SharedService: SharedService,
    private mat_dlg: MatDialog,
  ) {
    super();
    this.exceptionService.getMsg().pipe(take(2)).subscribe((msg)=>{
      if(msg == 'mapping'){
      this.getInvoiceFulldata('');
      }
    })
  }

  ngOnInit(): void {
    this.initialData();
    this.readFilePath();
    this.AddPermission();
    this.isAdmin =  this.dataService.isAdmin;
    
  }
  idleTimer(time, str) {
    this.timer = new IdleTimer({
      timeout: time, //expired after 180 secs
      clean: str,
      onTimeout: () => {
        if (this.router.url.includes('comparision-docs')) {
          if (this.router.url.includes('vendorPortal')) {
            this.router.navigate(['/vendorPortal/ExceptionManagement']);
          } else {
            this.router.navigate(['/customer/ExceptionManagement']);
          }
          this.AlertService.errorObject.detail =
            'Session Expired for Editing Invoice';
          this.messageService.add(this.AlertService.errorObject);
        }
      },
    });
  }

  updateSessionTime() {
    let sessionData = {
      session_status: true,
      "client_address": JSON.parse(localStorage.getItem('userIp'))
    };
    this.exceptionService
      .updateDocumentLockInfo(JSON.stringify(sessionData))
      .subscribe((data: any) => {});
  }

  initialData() {
    if (this.router.url.includes('invoice/InvoiceDetails/vendorUpload')) {
      this.vendorUplaodBoolean = true;
    }
    this.routeIdCapture = this.activatedRoute.params.subscribe((params) => {
      this.SharedService.invoiceID = params['id'];
      this.exceptionService.invoiceID = params['id'];
      this.invoiceID = params['id'];
    });
      
    
      this.getInvoiceFulldata('');
      this.getRulesData();
      this.readLineItems();
      this.readErrorTypes();
      this.readMappingData();
      this.readPOData();
      if (this.tagService.editable == true) {
      this.updateSessionTime();
      this.idleTimer(180, 'Start');
      this.callSession = setTimeout(() => {
        this.updateSessionTime();
      }, 250000);
    }
    this.onResize();
    this.Itype = this.tagService.type;
    if(this.Itype == 'Invoice'){
      this.getGRNtabData();
    }
    this.editable = this.tagService.editable;
    this.fin_boolean = this.tagService.financeApprovePermission;
    this.submitBtn_boolean = this.tagService.submitBtnBoolean;
    this.approveBtn_boolean = this.tagService.approveBtnBoolean;
    this.headerName = this.tagService.headerName;
    this.userDetails = this.authService.currentUserValue;
    this.approvalType = this.tagService.approvalType;
    this.financeapproveDisplayBoolean =
      this.settingService.finaceApproveBoolean;
    this.subStatusId = this.dataService.subStatusId;

    // this.showInvoice = "/assets/New folder/MEHTAB 9497.pdf"
    this.lineCompareData = comaprisionLineData;
  }
  getRulesData() {
    this.exceptionService.readBatchRules().subscribe((data: any) => {
      this.givenRules = data;
    });
  }
  AddPermission() {
    if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == false &&
      this.permissionService.financeApproveBoolean == false
    ) {
      this.editPermissionBoolean = true;
    } else if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == true &&
      this.permissionService.financeApproveBoolean == false
    ) {
      this.changeApproveBoolean = true;
    } else if (
      this.permissionService.editBoolean == true &&
      this.permissionService.changeApproveBoolean == true &&
      this.permissionService.financeApproveBoolean == true
    ) {
      this.financeApproveBoolean = true;
    }
  }

  changeTab(val:string) {
    this.currentTab = val;
    // if (val === 'show') {
    //   this.showPdf = true;
    //   this.btnText = 'Close';
    // } else {
    //   this.showPdf = false;
    //   this.btnText = 'View PDF';
    // }
    if (val.includes('line')) {
      this.lineTabBoolean = true;
    } else {
      this.lineTabBoolean = false;
    }
  }

  getInvoiceFulldata(str) {
    this.SpinnerService.show();
    this.inputDisplayArray = [];
    this.lineData = [];

    this.exceptionService.getInvoiceInfo().subscribe(
      (data: any) => {
        this.lineDisplayData = data.linedata.Result;
        let unit_index = 0;
        let price_index = 0;
        this.lineDisplayData.forEach((element,index) => {
          this.lineCount = element.items;
          this.lineData.push(element.items)
          if(element.tagname == 'UnitPrice'){
            price_index = index;

          } else if(element.tagname == 'Quantity'){
            unit_index = index;
          }
          if(element.tagname == 'AmountExcTax'){
              element.items.forEach((el,i)=>{
                if(el.linedetails?.length>0 && el.linedetails[0].poline?.length>0){
                  el.linedetails[0].poline[0].Value = (Number(this.lineDisplayData[unit_index].items[i].linedetails[0].poline[0].Value) * Number(this.lineDisplayData[price_index].items[i].linedetails[0].poline[0].Value)).toFixed(2)
                }
              })
          }
        });
        this.support_doc_list = data.support_doc?.files;
        if (this.support_doc_list == null) {
          this.support_doc_list = []
        }
        const pushedArrayHeader = [];
        data.headerdata.forEach((element) => {
          this.mergedArray = {
            ...element.DocumentData,
            ...element.DocumentTagDef,
          };
          this.mergedArray.DocumentUpdates = element.DocumentUpdates;
          pushedArrayHeader.push(this.mergedArray);
        });
        this.inputData = pushedArrayHeader;
        let inv_num_data:any = this.inputData.filter(val=>{
          return val.TagLabel == 'InvoiceId';
        })
        let PO_doc_num:any = this.inputData.filter(val=>{
          return val.TagLabel == 'PurchaseOrder';
        })
        this.invoiceNumber = inv_num_data[0]?.Value;
        this.po_num = PO_doc_num[0]?.Value
        this.readPOLines(this.po_num);
        this.getPODocId(this.po_num);
        this.vendorData = {
          ...data.Vendordata[0].Vendor,
          ...data.Vendordata[0].VendorAccount,
          ...data.Vendordata[0].VendorUser,
        };
        this.vendorAcId = this.vendorData['idVendorAccount'];
        this.vendorName = this.vendorData['VendorName'];
        this.selectedRule = data.ruledata[0].Name;
        this.poList = data.all_pos;

        if(str != 'batch'){
            this.SpinnerService.hide();
        }
      },
      (error) => {
        this.SpinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Server error',
        });
      }
    );
  }

  readFilePath() {
    this.showInvoice = '';
    this.SpinnerService.show();
    this.exceptionService.readFilePath().subscribe(
      (data: any) => {
        this.content_type = data?.content_type;
        if (data.filepath && data.content_type == 'application/pdf') {
          this.isPdfAvailable = false;
          this.isImgBoolean = false;
          this.byteArray = new Uint8Array(
            atob(data.filepath)
              .split('')
              .map((char) => char.charCodeAt(0))
          );
          this.showInvoice = window.URL.createObjectURL(
            new Blob([this.byteArray], { type: 'application/pdf' })
          );
        } else if (data.content_type == 'image/jpg' || data.content_type == 'image/png') {
          this.isPdfAvailable = false;
          this.isImgBoolean = true;
          this.byteArray = new Uint8Array(
            atob(data.filepath)
              .split('')
              .map((char) => char.charCodeAt(0))
          );
          this.showInvoice = window.URL.createObjectURL(
            new Blob([this.byteArray], { type: data.content_type })
          );
          // this.loadImage();
        } else {
          this.isPdfAvailable = true;
          this.showInvoice = '';
        }
        this.SpinnerService.hide();
      },
      (error) => {
        this.SpinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Server error',
        });
      }
    );
  }
  DownloadPDF() {
    let extension;
    if(this.content_type == 'application/pdf'){
      extension = '.pdf';
    } else if(this.content_type == 'image/jpg'){
      extension = '.jpg';
    } else if(this.content_type == 'image/png'){
      extension = '.png';
    }
    fileSaver.saveAs(this.showInvoice, `${this.vendorName}_${this.invoiceNumber}${extension}`);
  }
  loadImage() {
    if (this.isImgBoolean == true) {
      setTimeout(() => {
        this.zoomVal = 1;
        (<HTMLDivElement>document.getElementById('parentDiv')).style.transform =
          'scale(' + this.zoomVal + ')';

        const canvas = <HTMLCanvasElement>document.getElementById('canvas1');
        canvas.height = window.innerHeight;
        canvas.width = window.innerWidth;
        const ctx = canvas.getContext('2d');
        let image = new Image();
        image.src = this.showInvoice;
        image.onload = function () {
          // Calculate the aspect ratio of the image
          const imageAspectRatio = image.width / image.height;
          // Calculate the aspect ratio of the canvas
          const canvasAspectRatio = canvas.width / canvas.height;

          // Set the dimensions of the image to fit the canvas while maintaining the aspect ratio
          let imageWidth, imageHeight;
          if (imageAspectRatio > canvasAspectRatio) {
            // The image is wider than the canvas, so set the width of the image to the width of the canvas
            // and scale the height accordingly
            imageWidth = canvas.width;
            imageHeight = imageWidth / imageAspectRatio;
          } else {
            // The image is taller than the canvas, so set the height of the image to the height of the canvas
            // and scale the width accordingly
            imageHeight = canvas.height;
            imageWidth = imageHeight * imageAspectRatio;
          }

          // Draw the image on the canvas
          ctx.drawImage(image, 0, 0, imageWidth, imageHeight);
        };


      }, 50);
    }
  }

  onChangeValue(key, value, data) {
    // this.inputData[0][key]=value;
    let newValue = value;
    if(key == 'InvoiceDate'){
      const [day, month, year] = value.split('-');

      newValue = `${year}-${month}-${day}`;
    }
    let updateValue = {
      documentDataID: data.idDocumentData,
      OldValue: data.Value || '',
      NewValue: newValue,
    };
    this.updateInvoiceData.push(updateValue);
  }
  onChangeLineValue(value, data) {
    let updateValue = {
      documentLineItemID: data.idDocumentLineItems,
      OldValue: data.Value ||'',
      NewValue: value,
    };
    this.updateInvoiceData.push(updateValue);
  }

  saveChanges() {
    if (this.updateInvoiceData.length != 0) {
      this.SharedService.updateInvoiceDetails(
        JSON.stringify(this.updateInvoiceData)
      ).subscribe((data: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Saved',
          detail: 'Changes saved successfully',
        });

        this.updateInvoiceData = [];
      }, err=>{
        this.updateInvoiceData = [];
        this.messageService.add({
          severity : "error",
          summary : "error",
          detail : "Server error or Please check the data"
        })
      });
    }
  }
  onSubmitData() {
    // this.SpinnerService.show();
    // console.log(this.updateInvoiceData);
    // this.SharedService.updateInvoiceDetails(JSON.stringify(this.updateInvoiceData)).subscribe((data: any) => {
    //   console.log(data);
    //   if (data.result == 'success') {
    //     this.messageService.add({
    //       severity: "info",
    //       summary: "Updated",
    //       detail: "Updated Successfully"
    //     });
    //     this.getInvoiceFulldata();
    //   } else {
    //     this.messageService.add({
    //       severity: "error",
    //       summary: "error",
    //       detail: "Something went wrong"
    //     });
    //   }
    //   this.updateInvoiceData = [];
    //   this.SpinnerService.hide();
    // })
  }

  drawrectangleonHighlight(index) {
    // var rect = new fabric.Rect({
    //   left: 100,
    //   top: 50,
    //   fill: 'rgba(255,0,0,0.5)',
    //   width: 100,
    //   height: 30,
    //   selectable: false,
    //   lockMovementX: true,
    //   lockMovementY: true,
    //   lockRotation: true,
    //   transparentCorners: true,
    //   hasControls: false,
    // });

    // this.canvas[index].add(rect);
    // this.canvas[index].setActiveObject(rect);
    // document.getElementById(index + 1).scrollIntoView();
  }

  zoomin() {
    this.zoomVal = this.zoomVal + 0.2;
    this.zoomX = this.zoomX + 0.05;
    if (this.zoomVal >= 2.0 && this.zoomX >= 2.0) {
      this.zoomVal = 1;
      this.zoomX = 1;
    }
    (<HTMLDivElement>document.getElementById('canvas1')).style.transform = `scale(${this.zoomX},${this.zoomVal})`;
  }

  zoomout(index) {
    this.zoomVal = this.zoomVal - 0.2;
    this.zoomX = this.zoomX - 0.05;
    if (this.zoomVal <= 0.5 && this.zoomX <= 0.8) {
      this.zoomVal = 1;
      this.zoomX = 1;
    }
    (<HTMLDivElement>document.getElementById('canvas1')).style.transform = `scale(${this.zoomX},${this.zoomVal})`;
  }

  removeEvents(index) {
    this.canvas[index].off('mouse:down');
    this.canvas[index].off('mouse:up');
    this.canvas[index].off('mouse:move');
  }

  panning(index) {
    // this.removeEvents(index);
    // let panning = false;
    // let selectable;
    // this.canvas[index].on('mouse:up', (e) => {
    //   panning = false;
    // });

    // this.canvas[index].on('mouse:down', (e) => {
    //   panning = true;
    //   selectable = false;
    // });
    // this.canvas[index].on('mouse:move', (e) => {
    //   if (panning && e && e.e) {
    //     selectable = false;
    //     var units = 10;
    //     var delta = new fabric.Point(e.e.movementX, e.e.movementY);
    //     this.canvas[index].relativePan(delta);
    //   }
    // });
  }

  addVendorDetails() {
  }
  onVerify(e) {
  }
  submitChanges() {
    // if (this.userDetails.user_type == 'customer_portal') {
    //   let submitData = {
    //     "documentdescription": " "
    //   }
    //   this.SpinnerService.show();
    //   this.SharedService.submitChangesInvoice(JSON.stringify(submitData)).subscribe((data: any) => {
    //     this.dataService.invoiceLoadedData = [];
    //     if (data.result) {
    //       this.messageService.add({
    //         severity: "success",
    //         summary: "Updated",
    //         detail: "Updated Successfully"
    //       });
    //       this.SpinnerService.hide();
    //       setTimeout(() => {
    //         this._location.back()
    //       }, 1000);
    //     }
    //   }, error => {
    //     this.messageService.add({
    //       severity: "error",
    //       summary: "error",
    //       detail: error.error
    //     });
    //     this.SpinnerService.hide();
    //   })
    // } else if (this.userDetails.user_type == 'vendor_portal') {
    //   this.SharedService.vendorSubmit().subscribe((data: any) => {
    //     console.log(data);
    //     this.messageService.add({
    //       severity: "success",
    //       summary: "Uploaded",
    //       detail: "Uploaded to serina successfully"
    //     });
    //     setTimeout(() => {
    //       this.router.navigate(['vendorPortal/invoice/allInvoices']);
    //     }, 1000);
    //   }, error => {
    //     this.messageService.add({
    //       severity: "error",
    //       summary: "error",
    //       detail: error.statusText
    //     });
    //   })
    // }
  }

  approveChangesManual() {
    this.exceptionService.send_manual_approval().subscribe(
      (data: any) => {
        this.AlertService.addObject.detail =
          'Send to Manual approval successfully';
        this.messageService.add(this.AlertService.addObject);
        setTimeout(() => {
          this._location.back();
        }, 2000);
      },
      (error) => {
        this.AlertService.errorObject.detail = error.statusText;
        this.messageService.add(this.AlertService.errorObject);
      }
    );
  }

  approveChangesBatch() {
    this.getInvoiceFulldata('batch');
    setTimeout(() => {
      let count = 0;
      let errorType: string;
      let errorTypeHead: string;
      let errorTypeLine: string;
      this.inputData.forEach((data:any)=>{
        if((data.TagLabel == 'InvoiceTotal') ||  (data.TagLabel == 'SubTotal') ){
          if(data.Value == '' || isNaN(+data.Value)){
            count++
            errorTypeHead = 'AmountHeader';
          } 
        } else if((data.TagLabel == 'PurchaseOrder') || (data.TagLabel == 'InvoiceDate') || (data.TagLabel == 'InvoiceId')){
          if(data.Value == ''){
            count++
            errorType = 'emptyHeader';
          } 
        }
      })
  
      this.lineDisplayData.forEach((element) => {
        if (
          element.tagname == 'Quantity' ||
          element.tagname == 'UnitPrice' ||
          element.tagname == 'AmountExcTax' ||
          element.tagname == 'Amount'
        ) {
          element.items.forEach((ele) => {
            ele.linedetails.forEach((ele1) => {
              if (
                ele1.invline[0].DocumentLineItems?.Value == '' ||
                isNaN(+ele1.invline[0].DocumentLineItems?.Value)
              ) {
                count++;
                errorType = 'emptyHeader';
              }

              if(element.tagname == 'Quantity'){
                if (
                  ele1.invline[0].DocumentLineItems?.Value == 0 
                ) {
                  count++;
                  errorTypeLine = 'quntity';
                }
              }
            });
          });
        }
      });
      if (count == 0) {
        this.sendToBatch();
      } else {
        /* Error reponse starts*/
        if (errorTypeHead == 'AmountHeader') {
          setTimeout(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail:
                "Please verify the 'Sub-Total' and 'Invoice-Total' on the Header",
            });
          }, 50);
        }
        if (errorType == 'emptyHeader') {
          this.AlertService.errorObject.detail =
            'Please Check the PO Number, Invoice Date, and Invoice-Id fields on the header';
          this.messageService.add(this.AlertService.errorObject);
        }
        if (errorTypeLine == 'AmountLine') {
          setTimeout(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail:
                'Please verify the Amount, Quantity, Unit price and Amount-Excluding-Tax on the Line details',
            });
          }, 10);
        } else if(errorTypeLine == 'quntity'){
          setTimeout(() => {
            this.messageService.add({
              severity: 'error',
              summary: 'error',
              detail:
                "Please check the 'Quantity' in the Line details",
            });
          }, 10);
        }
        this.SpinnerService.hide();
        /* Error reponse end*/
      }
    }, 2000);

  }

  sendToBatch() {
    this.SpinnerService.show()
    this.exceptionService.send_batch_approval().subscribe(
      (data: any) => {
        this.dataService.invoiceLoadedData = [];
        // this.SpinnerService.hide();
        this.AlertService.addObject.detail = 'Sent to Batch Successfully!';
        this.AlertService.addObject.summary = 'sent';
        this.messageService.add(this.AlertService.addObject);
        this.syncBatch();

      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Server error',
        });
      }
    );
  }
  syncBatch(){
    this.SpinnerService.show();
    this.SharedService.syncBatchTrigger(`?re_upload=false`,'').subscribe((data: any) => {
      this.headerpop = 'Batch Progress';
      this.p_width = '350px';
      this.progressDailogBool = true;
      this.GRNDialogBool = false;
      this.batchData = data[this.invoiceID]?.complete_batch_cycle;
      let last_msg = this.batchData[this.batchData.length - 1].msg;
      this.isBatchFailed = false;
      if( last_msg == 'Batch ran to an Exception!' || last_msg == 'Matching Failed - Batch Failed' && this.batch_count <=2){
        this.batch_count++;
        this.isBatchFailed = true;
      }
      if(!(this.batch_count <=2)){
        this.AlertService.errorObject.detail = "Dear User, Kindly check with Serina's support team regarding this invoice.";
        this.messageService.add(this.AlertService.errorObject);
        // setTimeout(() => {
        //   this.router.navigate([`${this.portalName}/ExceptionManagement`])
        // }, 2000);
      }
      this.SpinnerService.hide();
    },err=>{
      this.SpinnerService.hide();
    });
  }

  routeToMapping() {
    this.exceptionService.invoiceID = this.invoiceID;
    this.tagService.editable = true;
    this.tagService.submitBtnBoolean = true;
    this.tagService.headerName = 'Edit Invoice';
    let sub_status = null;
    this.refreshPOBool = false;
    for (const el of this.batchData) {
      if (el.status == 0) {
        sub_status = el.sub_status;
      }
    };
    if(!sub_status){
      sub_status = this.batchData[this.batchData.length-1].sub_status;
    }
    this.subStatusId = sub_status;
    this.dataService.subStatusId = sub_status;
    if (this.portalName == 'vendorPortal') {
      if (sub_status == 8 ||
        sub_status == 16 ||
        sub_status == 18 ||
        sub_status == 19 ||
        sub_status == 33 ||
        sub_status == 21 ||
        sub_status == 27) {
          if(sub_status == 18){
            this.AlertService.updateObject.detail = "Invoice Total and 'Sub-Total+Tax' Mismatch Identified. Kindly check Entry.";
          } else if(sub_status == 19){
            this.AlertService.updateObject.detail = 'Dear User, Sub total is not matching with the invoice lines total.';
          } else {
            this.getInvoiceFulldata('');
            this.AlertService.updateObject.detail = 'Please check the values in invoice.';
          }
        this.AlertService.updateObject.summary = 'Suggestion';
        this.messageService.add(this.AlertService.updateObject);
        this.refreshPOBool = true;
      } else {
        this.router.navigate([`${this.portalName}/invoice/allInvoices`]);
      }
    } else {
      if (sub_status == 8 ||
        sub_status == 16 ||
        sub_status == 17 ||
        sub_status == 18 ||
        sub_status == 19 ||
        sub_status == 33 ||
        sub_status == 21 ||
        sub_status == 27 ||
        sub_status == 75) {
        this.AlertService.updateObject.summary = 'Suggestion';
        if(sub_status == 18){
          this.AlertService.updateObject.detail = "Invoice Total and 'Sub-Total+Tax' Mismatch Identified. Kindly check Entry.";
        } else if(sub_status == 19){
          this.AlertService.updateObject.detail = 'Dear User, Sub total is not matching with the invoice lines total.';
        } else {
          this.getInvoiceFulldata('');
          this.AlertService.updateObject.detail = 'Please check the values in invoice.';
        }
        this.messageService.add(this.AlertService.updateObject);
        this.refreshPOBool = true;
      } else if (sub_status == 34) {
        this.refreshPOBool = true;
        this.AlertService.updateObject.summary = 'Suggestion';
        this.AlertService.updateObject.detail = "Please compare the PO lines with invoices. We generally recommend the 'PO flip' method to resolve issues of this type. ";
        this.messageService.add(this.AlertService.updateObject)
      } else if (sub_status == 7 || sub_status == 23 || sub_status == 10 || sub_status == 35 || sub_status == 23 ) {
        this.router.navigate([`${this.portalName}/ExceptionManagement`]);
      } else {
        this.router.navigate([`${this.portalName}/invoice/allInvoices`]);
      }
    }

    this.progressDailogBool = false;
  }



  financeApprove() {
    // this.SharedService.financeApprovalPermission().subscribe(
    //   (data: any) => {
    //     this.dataService.invoiceLoadedData = [];
    //     this.messageService.add({
    //       severity: 'success',
    //       summary: 'Approved',
    //       detail: data.result,
    //     });
    //     setTimeout(() => {
    //       this._location.back();
    //     }, 1000);
    //   },
    //   (error) => {
    //     this.messageService.add({
    //       severity: 'error',
    //       summary: 'error',
    //       detail: error.statusText,
    //     });
    //   }
    // );
  }

  open_modal() {
    this.displayRuleDialog = true;
    this.save_rule_boolean = true;
  }

  send_review_modal() {
    this.displayRuleDialog = true;
    this.save_rule_boolean = false;
  }

  save_rule() {
    this.selectedRule = this.SelectRuleOption.value['Name'];
    this.selectedRuleID = this.SelectRuleOption.value['idDocumentRules'];
    this.displayRuleDialog = false;
  }

  sendReview() {
    this.selectedRule = this.SelectRuleOption.value['Name'];
    this.selectedRuleID = this.SelectRuleOption.value['idDocumentRules'];
    this.exceptionService
      .send_batch_approval_review(this.selectedRuleID)
      .subscribe(
        (data: any) => {
          this.AlertService.addObject.detail =
            'Send to Batch review successfully';
          this.messageService.add(this.AlertService.addObject);
          this.displayRuleDialog = false;
          setTimeout(() => {
            this._location.back();
          }, 2000);
        },
        (error) => {
          this.AlertService.errorObject.detail = error.statusText;
          this.messageService.add(this.AlertService.errorObject);
        }
      );
  }

  reviewManualApprove() {
    if (confirm(`Are you sure you want to send for Manual approval?`)) {
      this.exceptionService.send_review_manual().subscribe(
        (data: any) => {
          this.AlertService.addObject.detail =
            'Send to Manual approval review successfully';
          this.messageService.add(this.AlertService.addObject);
          setTimeout(() => {
            this._location.back();
          }, 2000);
        },
        (error) => {
          this.AlertService.errorObject.detail = error.statusText;
          this.messageService.add(this.AlertService.errorObject);
        }
      );
    }
  }
  
  backToInvoice() {
    if (this.userDetails.user_type == 'customer_portal') {
      this.router.navigate(['/customer/ExceptionManagement']);
    } else if (
      this.userDetails.user_type == 'vendor_portal' &&
      this.submitBtn_boolean == true
    ) {
      if (
        confirm(
          ` Are you sure you want cancel process ? \n if you click OK you will lost your invoice meta data.`
        )
      ) {
        this._location.back();
      }
    } else {
      this._location.back();
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerHeight = window.innerHeight;
    if (this.innerHeight > 550 && this.innerHeight < 649) {
      this.InvoiceHeight = 500;
    } else if (this.innerHeight > 650 && this.innerHeight < 700) {
      this.InvoiceHeight = 560;
    } else if (this.innerHeight > 750) {
      this.InvoiceHeight = 660;
    }
  }
  zoomIn() {
    this.zoomdata = this.zoomdata + 0.1;
  }
  zoomOut() {
    this.zoomdata = this.zoomdata - 0.1;
  }
  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }
  textLayerRendered(e: CustomEvent) {
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }
  selectedText(): void {
  }

  search(stringToSearch: string) {
    this.pdfViewer.pdfFindController.executeCommand('find', {
      caseSensitive: false,
      findPrevious: undefined,
      highlightAll: true,
      phraseSearch: true,
      query: stringToSearch,
    });
  }
  hightlight(val) {
    var pageno = parseInt('1');
    var pageView = this.pdfViewer.pdfViewer._pages[pageno - 1];
    //datas - array returning from server contains synctex output values
    var left = parseInt('530px');
    var top = parseInt('660px');
    var width = parseInt('50px');
    var height = parseInt('20px');
    //recalculating top value
    top = pageView.viewport.viewBox[3] - top;
    var valueArray = [left, top, left + width, top + height];
    let rect = pageView.viewport.convertToViewportRectangle(valueArray);
    // rect       = PDFJS.disableTextLayer.normalizeRect(rect);
    var x = Math.min(rect[0], rect[2]),
      width = Math.abs(rect[0] - rect[2]);
    var y = Math.min(rect[1], rect[3]),
      height = Math.abs(rect[1] - rect[3]);
    const element = document.createElement('div');
    element.setAttribute('class', 'overlay-div-synctex');
    element.style.left = x + 'px';
    element.style.top = y + 'px';
    element.style.width = width + 'px';
    element.style.height = height + 'px';
    element.style.position = 'absolute';
    element.style.backgroundColor = 'rgba(200,0,0,0.5)';
    // $('*[data-page-number="' + pageno + '"]').append(element);
    // this.pdfviewer.pdfViewer._scrollIntoView({
    //   pageDiv: pageView.div,
    // });
  }

  onClick(e) {
    const textLayer = document.getElementsByClassName('TextLayer');
    const x =
      window.getSelection().getRangeAt(0).getClientRects()[0].left -
      textLayer[0].getBoundingClientRect().left;
    const y =
      window.getSelection().getRangeAt(0).getClientRects()[0].top -
      textLayer[0].getBoundingClientRect().top;
  }

  viewPdf() {
    this.showPdf = !this.showPdf;
    if (this.showPdf != true) {
      this.btnText = 'View PDF';
    } else {
      this.btnText = 'Close';
    }
    if(this.isImgBoolean == true){
      this.loadImage();
    }
  }
  rotate(angle: number) {
    this.rotation += angle;
  }

  filterPO(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.poList.length; i++) {
      let country = this.poList[i];
      if (
        country.PODocumentID.toLowerCase().indexOf(query.toLowerCase()) == 0
      ) {
        filtered.push(country);
      }
    }
    this.filteredPO = filtered;
  }

  onSelectPO(value) {
    if (confirm(`Are you sure you want to change PO Number?`)) {
      this.exceptionService.updatePONumber(value.PODocumentID).subscribe(
        (data: any) => {
          this.AlertService.addObject.detail = 'PO Number updated successfully';
          this.messageService.add(this.AlertService.addObject);
          this.getInvoiceFulldata('');
        },
        (error) => {
          this.AlertService.errorObject.detail = error.statusText;
          this.messageService.add(this.AlertService.errorObject);
        }
      );
    }
  }

  readLineItems() {
    this.exceptionService.readLineItems().subscribe((data: any) => {
      this.lineItems = data.description;
    });
  }

  readErrorTypes() {
    this.exceptionService.readErrorTypes().subscribe((data: any) => {
      this.givenErrors = data.description;
    });
  }

  lineMapping(val, el, val1) {
    let itemCodeArray = [];
    let presetBoolean: boolean = false;

    this.inv_itemcode = val;
    this.po_itemcode = el;
    if (itemCodeArray.length > 1) {
      presetBoolean = itemCodeArray.includes(el);
    }
    if (this.mappedData?.length > 0) {
      let presetArray = this.mappedData?.filter((ele1) => {
        return ele1.ItemMetaData?.itemcode == el;
      });
      if (presetArray.length > 0) {
        presetBoolean = true;
      }
    }
    if (presetBoolean) {
      if (confirm('Lineitem already mapped, you want to change it again')) {
        this.displayErrorDialog = true;
      }
    } else {
      itemCodeArray.push(el);
      this.displayErrorDialog = true;
    }
  }

  cancelSelectErrorRule() {
    this.displayErrorDialog = false;
  }

  updateLine() {
    this.exceptionService
      .updateLineItems(
        this.inv_itemcode,
        this.po_itemcode,
        this.SelectErrorOption,
        this.vendorAcId
      )
      .subscribe(
        (data: any) => {
          this.displayErrorDialog = false;
          this.AlertService.addObject.detail = 'Line item updated successfully';
          this.messageService.add(this.AlertService.addObject);
          this.getInvoiceFulldata('');
          this.readMappingData();
        },
        (error) => {
          this.AlertService.errorObject.detail = error.statusText;
          this.messageService.add(this.AlertService.errorObject);
          this.displayErrorDialog = false;
        }
      );
  }

  readMappingData() {
    this.exceptionService.readMappedData().subscribe((data: any) => {
      this.mappedData = data?.description;
    });
  }

  Reject() {
    let rejectionData = {
      documentdescription: this.rejectionComments,
      userAmount: 0,
    };

      this.SharedService.vendorRejectInvoice(
        JSON.stringify(rejectionData)
      ).subscribe(
        (data: any) => {
          this.dataService.invoiceLoadedData = [];
          this.messageService.add({
            severity: 'success',
            summary: 'Rejected',
            detail: 'Rejection Notification sent to Vendor',
          });
          this.displayrejectDialog = false;
          setTimeout(() => {
            this._location.back();
          }, 1000);
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'error',
            detail: 'Something went wrong',
          });
        }
      );
      
  }
  open_dialog_comp(str){
    this.SpinnerService.show();
    this.getPO_lines(str);
  }
  
  getPO_lines(str){
    let query = `?inv_id=${this.invoiceID}`;
    this.exceptionService.getPOLines(query).subscribe((data:any)=>{
      this.poLineData = data.Po_line_details;
      this.mat_dlg.open(PopupComponent,{ 
        width : '60%',
        height: '70vh',
        hasBackdrop: false,
        data : { type: str, comp:'line', resp: this.poLineData,grnLine:'',po_num:''}});
      this.SpinnerService.hide();
    },err=>{
      this.AlertService.errorObject.detail = "Server error";
      this.messageService.add(this.AlertService.errorObject);
      this.SpinnerService.hide();
    })
  }

  readPOLines(po_num) {
    this.SharedService.readPOLines(po_num).subscribe((data: any) => {
      // this.poLineData = data.PODATA;
      // this.UniqueGRN = data?.GRNDATA?.filter((val1,i,a)=> a.findIndex(val2=>val2.PackingSlip == val1.PackingSlip) === i);
      this.GRNData = data?.GRNDATA
      // let jsonObj = data?.GRNDATA?.map(JSON.stringify);
      // let uniqeSet = new Set(jsonObj);
      // let unique = Array?.from(uniqeSet)?.map(JSON.parse);
      
      this.po_grn_list = data?.GRNDATA.filter((val1,index,arr)=> arr.findIndex(v2=>['PackingSlip'].every(k=>v2[k] ===val1[k])) === index);
    }, err => {
      this.AlertService.errorObject.detail = "Server error";
      this.messageService.add(this.AlertService.errorObject);
    })
  }
  addGrnLine(val){
    this.po_grn_line_list = [];
    val?.value?.forEach(ele=>{
      this.GRNData.filter(el=>{
        if(ele.PackingSlip == el.PackingSlip){
          this.po_grn_line_list.push(el)
        }
      });
    })
    let arr = [];
    this.po_grn_line_list?.forEach(val=>{
        let ele = `${val.PackingSlip}-${val.POLineNumber}-${val.Name}`;
        arr.push({PackingSlip:val.PackingSlip,POLineNumber:val.POLineNumber,GRNField:ele});
      })
      this.po_grn_line_list = arr.filter((val1,index,arr)=> arr.findIndex(v2=>['PackingSlip','POLineNumber'].every(k=>v2[k] ===val1[k])) === index);
    this.PO_GRN_Number_line = this.po_grn_line_list;
  }
  filterPO_GRNnumber(event){
    let filtered: any[] = [];
    let query = event.query;

    if (this.po_grn_list?.length > 0) {
      for (let i = 0; i < this.po_grn_list?.length; i++) {
        let PO: any = this.po_grn_list[i];
        if (PO.GRNField.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(PO);
        }
      }
    }
    this.filteredPO_GRN = filtered;
  }
  editGRNData(){
    this.getGrnData();
    this.displayRuleDialog = true;
  }
  getGrnData(){
    this.SpinnerService.show();
    this.exceptionService.get_grn_data().subscribe((data:any)=>{
      this.PO_GRN_Number_line = data;
      this.SpinnerService.hide();
    },err=>{
      this.SpinnerService.hide();
    })
  }
  deleteGRNEdit(index,data){
    this.PO_GRN_Number_line.splice(index,1)
  }
  ChangeGRNData(){
    let grnNumberList = [];
    this.PO_GRN_Number_line.forEach(el=>{
      grnNumberList.push(el.PackingSlip) 
    })
    grnNumberList = [...new Set(grnNumberList)]
    let obj ={
      "MultiPoList":this.PO_GRN_Number_line,
      "grn_documentID": grnNumberList.toString()
    }
    if(confirm("Are you sure you want to change GRN data?")){
      this.SpinnerService.show();
      this.exceptionService.update_GRN_data(obj).subscribe((data:any)=>{
        this.SpinnerService.hide();
        this.AlertService.addObject.detail = "GRN Data updated";
        this.messageService.add(this.AlertService.addObject);
        this.displayRuleDialog = false;
      }, err=>{
        this.SpinnerService.hide();
        this.AlertService.errorObject.detail = "Please try after sometime";
        this.messageService.add(this.AlertService.errorObject);
        this.displayRuleDialog = false;
      })
    }
  }



  getPODocId(po_num){
    this.SharedService.get_poDoc_id(po_num).subscribe((data:any)=>{
      this.poDocId = data.result;
    })
  }
  readPOData(){
    let query = `?inv_id=${this.invoiceID}`;
    this.exceptionService.getPOLines(query).subscribe((data:any)=>{
      this.poLineData = data.Po_line_details;
      console.log(this.poLineData)
      if(Object.keys(this.poLineData[0]).length>0){
        this.POlineBool = true;
      } else {
        this.POlineBool = false;
      }
      this.SpinnerService.hide();
    },err=>{
      this.AlertService.errorObject.detail = "Server error";
      this.messageService.add(this.AlertService.errorObject);
      this.SpinnerService.hide();
    })
  }
  refreshPO(){
    this.SpinnerService.show();
    this.SharedService.updatePO(this.poDocId).subscribe((data:any)=>{
      this.readPOData();
      this.SpinnerService.hide();
      this.AlertService.addObject.detail = 'PO data updated.';
      this.messageService.add(this.AlertService.addObject);
    },err=>{
      this.SpinnerService.hide();
      this.AlertService.errorObject.detail = 'Server error';
      this.messageService.add(this.AlertService.errorObject);
    })
  }

  onSelectFile(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.uploadFileList.push(event.target.files[i]);

    }
  }
  removeUploadQueue(index) {
    this.uploadFileList.splice(index, 1);
  }

  uploadSupport() {
    this.progress = 1;
    const formData: any = new FormData();
    for (const file of this.uploadFileList) {
      formData.append('files', file, file.name);
    }
    this.SpinnerService.show()
    this.SharedService.uploadSupportDoc(formData)
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {
            this.progress = null;
            this.AlertService.addObject.detail = "Supporting Documents uploaded Successfully";
            this.messageService.add(this.AlertService.addObject);
            this.uploadFileList = [];
            this.support_doc_list = [];
            event.body?.result?.forEach(ele => {
              this.support_doc_list.push(ele);
            })
            //  setTimeout(() => {
            //  this.getInvoiceFulldata();
            this.SpinnerService.hide()
          }
        }),
        catchError((err: any) => {
          this.progress = null;
          this.AlertService.errorObject.detail = 'Server error';
          this.messageService.add(this.AlertService.errorObject);
          this.SpinnerService.hide()
          return throwError(err.message);
        })
      )
      .toPromise();
  }

  downloadDoc(doc_name) {
    let encodeString = encodeURIComponent(doc_name);
    this.SharedService.downloadSupportDoc(encodeString).subscribe(
      (response: any) => {
        let blob: any = new Blob([response]);
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(blob, doc_name);
        this.AlertService.addObject.detail =
          'Document downloaded successfully.';
        this.messageService.add(this.AlertService.addObject);
      },
      (err) => {
        this.AlertService.errorObject.detail = 'Server error';
        this.messageService.add(this.AlertService.errorObject);
      }
    );
  }
  getGRNtabData(){
    this.SharedService.getGRNTabData().subscribe((data: any) => {
      this.GRNTabData = data?.result;
      this.grnTabDatalength = Object.keys(this.GRNTabData).length;
    })
  }
  ngOnDestroy() {
    // if( this.editable == true){
      let sessionData = {
        session_status: false,
        "client_address": JSON.parse(localStorage.getItem('userIp'))
      };
      this.exceptionService
        .updateDocumentLockInfo(sessionData)
        .subscribe((data: any) => {});
      // clearInterval(this.timer);
      clearTimeout(this.callSession);
    // }
    // this.timer = null;
    this.AlertService.addObject.severity = 'success';
    this.tagService.financeApprovePermission = false;
    this.tagService.approveBtnBoolean = false;
    this.tagService.submitBtnBoolean = false;
    this.mat_dlg.closeAll();
  }
}
